.my-pagination ::ng-deep .ngx-pagination .current {
  background: rgb(34, 34, 34);
  margin-top: 14px;
}
#veg-img-prt {
    margin-right: -8px !important;
}
.inq-sp-1 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;
}

.product-lisitng{
  .inq-cart-bw-st{
    width: fit-content;
  }
  .inq-sp-2{
    padding: 0px 18px;
  }
}

#productRow{
  padding-top: 20px !important;
}
#totalItems{
  font-size: 14px; margin-top: 10px;
}
#productListHead
{
  margin-bottom: 2px !important;
}
.breadCrumbListing
{
  margin-bottom: 5px !important;
}

.listProduct
{
  cursor: pointer;
}
.inq-sp-3 i{
    font-size: 12px !important;
    background: #333;
    color: #fff;
    padding: 3px;
    border-radius: 25px;
    width: 18px;
    height: 18px;

}
.inq-sp-1 i:hover,.inq-sp-3 i:hover{
    background: $pallet2;
    color: #fff;
    cursor: pointer;   
}

@import "../../../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
.wishlist-heart
{
float: right; 
color: #375673;
}
#img-list{
  cursor: pointer;
}
.img-fluid1{
    width: 350px;
    height: 375px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 10%;
  }

.productTitleList  
{
  transform: translate3d(0px, 0px, 0px);
}
  .product-image {
    width: 250px;
    max-width: 100%;
    height: 200px;
    max-height: 100%;
    overflow: hidden;
    // object-fit: contain;
}
#priceListing{
  color: #212529 !important
}
.add-cart{
  border-radius: 5px;
  background-color: $white !important;
}
.review-productList{
  color: #373b3f !important; 
  font-size: 11px !important
}
.rating{
  color: #373b3f !important; 
  font-size: 11px !important;
}
#pagination-productList{
  font-size: 14px; float: right;
}
.add-cart1{
  color: $white;
  border-radius: 5px;
  // background-color: #8c945b !important;
  .inq-cart-bw-st{
    border-radius: 5px;
    border: 1px solid $footer-bg !important;
    background-color: $footer-bg !important;
  }
}



.inq-cart-bw-st{
  border: 1px solid $footer-bg !important;
  background-color: $white !important;
}

.add-cart:hover
{
  cursor: pointer;
}

.home:hover
{
  cursor: pointer;
}

.img-fluid{
  @include media-breakpoint-up(sm) {
      height: 250px;
  }

  @include media-breakpoint-down(sm) {
      height: 230px;
  }
}