.my-orders{
.box-orders{
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 35px 15px;
    -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
    box-shadow: 0 0 4px rgb(218, 216, 216);
    border-radius: 5px;
    width: 100%;
    background-color: $white;
}
}