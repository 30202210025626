.bombayhardware-card{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.bombayhardware-image{
    height: 150px !important;
    width: 120px !important;
}

.bombayhardware-first{
    padding: 10px 15px;
    height: 340px;
}

.bombayhardware-first-text{
    padding: 20px 10px;
}

.am-title{
    font-size: 1.5rem;
    font-weight: 300;
    color: #3d5266;
}

.bombayhardware-second{
    padding: 10px 15px;
}

// PRODUCT LISTING
.bombayhardware-card1{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    width: 410px;
    margin-left: 10px;
    margin-top: 15px;
}

.bombayhardware-first1{
    padding: 10px 10px;
    height: 340px;
}

.bombayhardware-image1{
    height: 120px !important;
    width: 100px !important;
}

.bombayhardware-first-text1{
    padding: 10px 5px;
    padding-left: 15px;
}

.am-title1{
    font-size: 1.1rem;
    font-weight: 300;
    color: #3d5266;
}

// COMPANY PROFILE

#am-product{
    font-size: 14px;
    font-family: 'proxima-regular','sans-serif' !important;
    .am-page-title {
        color: #1f1f1f;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 5px;
        font-family: 'proxima-regular','sans-serif' !important;
    }

    .container-fluid{
        padding:30px;
    }

    .ampage-header {
        margin-bottom: 1.875rem;
    }

    .card{
        border: 1px solid #ededed;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: fit-content;
    }

    .profile-view {
        position: relative;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-img {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        position: relative;
    }

    .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-info-left {
        border-right: 2px dashed #ccc;
    }

    .user-name {
        color: #333;
    }

    .personal-info {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        padding-top: 15px;
        .li{
            padding-top: 8px;
        }
    }

    .ami-title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 25%;
    }

    .ami-text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    a {
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }

    .ai-mt{
        margin-top: 5px;
    }

    .tab-box {
        border-bottom: 0;
        margin-bottom: 10px;
        padding: 0;
    }

    .user-tabs{
        padding: 10px 15px;
    }

    .amcard-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

// AIEMW NEW CARD
#bombayhardwarecards{
    font-family: 'proxima-regular','sans-serif' !important;
    .bombayhardware{
        min-height: 450px;
        margin: 0px 10px;
        padding: 10px 15px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: 1px solid #999;
        border-radius: 12px;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &-img-holder{
            display:block;
            height: 140px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            margin: 0.2px;
        }
        &-img{
            height: 130px;
            width: 130px;
            // border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            // overflow: hidden;
        }
        &-description-holder{
            display: block;
        }
        &-title{
            font-size: 20px;
            font-weight: 550;
            color: rgb(59, 59, 59);
        }
        &-subtitle{
            padding-top: 1px;
            padding-bottom: 6px;
            font-size: 15px;
            font-weight: 500;
            color: rgb(138, 138, 138);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-tabs{
            margin-top: 8px;
            ul{
                display: flex;
                justify-content: center;
            }
            li{
                padding: 6px 9px;
                padding-right: 10px;
                background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border-radius: 50%;
                margin-left: 10px;
                a{
                    i{
                    color: #fff !important;
                    font-size: 20px !important;
                    line-height: 10px !important;
                    width: 20px !important;
                }
            }
            }
            li:hover{
                box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                cursor: pointer;
            }
            .nav-tabs{
                border-bottom: none !important;
            }
        }
        &-profile{
            margin-bottom: 40px;
            margin-top:15px;
            .profile-title{
                color: rgb(138, 138, 138);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            .profile-description{
                color: rgb(65, 64, 64);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            a{
                font-size: 14px;
            }
        }
        &-contact{
            a{
                font-size: 14px;
            }
            margin-bottom: 40px;
            margin-top:15px;
            .contact-title{
                color: rgb(153, 153, 153);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
            }
            .contact-description{
                color: rgb(65, 64, 64);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                overflow-wrap: break-word;
            }
        }
        &-submit-btn{
            height: fit-content;
            position: absolute;
            bottom: 15px;
            width: 83%;
            .bombayhardware-btn{
                background: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);
                border: none !important;
                color: #fff;
            }
        }
        &-active{
            .bombayhardware-tabs{
                li{
                    background-image: linear-gradient(to right top, #E31E25, #E31E25, #E31E25, #E31E25, #E31E25);;
                }
            }
        }
    }
   
}

#bombayhardware-listing{
    position: relative;
    .bombayhardware{
        min-height: 420px;
        max-height: 420px;
        padding: 10px 10px;
        min-width: 270px;
        max-width: 270px;
        margin-bottom: 12px;
    }
    
}

