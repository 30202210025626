/* INTER-REGULAR-regular - latin */
@import '../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
// @import "../../../../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import '../../mini-cart.scss';

* {
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-decoration: none;
    @if $product-cd == "tams"{
        font-family: $theme-font;
    }
    @else{

    }
    
}

input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
}

.inq-secs-mt-5 {
    margin-top: 5rem !important;
}

.inq-fw-st {
    font-weight: 500;
}

.jumbotron {
    padding: 2rem 2rem !important;
}

.table td,
.table th {
    padding: .75rem 1rem !important;
}

a:hover {
    // color: #fff !important;
    text-decoration: none !important;
}

// header styles 
.inq-row {
    display: flex;
    flex-wrap: wrap;
}

.inq-htop-st {
    background: $primary;
    justify-content: center;
    position: relative;
    p {
        padding: .5rem 0;
        margin-bottom: 0rem;
        color: $white;
        font-weight: 400;
    }
    a {
        color: $white !important;
        border-bottom: 2px solid;
    }
    .fa {
        position: absolute;
        right: 122px;
        top: 10px;
        color: $white;
    }
}

.inq-Caro-st .inq-sli-btn-st p {
    span{
        font-size: 25px;
        color: $carousel-heading;
    }
    margin-bottom: 10px !important;
    font-size: 25px;
    @if $product-cd =="haven"{
        font-style: italic;
    }
    font-weight: 600;
}
.inq-sli-btn-st h3 {
    font-size: 38px;
    line-height: 40px;
    font-weight: 700;
}

.inq-hmid1-st {
    float: left;
    .nav-tabs {
        border-bottom: none;
        .nav-link {
            color: inherit;
            border: none;
            padding: .2rem 0rem;
            font-size: 12px;
            font-weight: 500;
            &:hover {
                color: inherit !important;
                border-color: none;
            }
        }
    }
    .dropdown-toggle::after {
        margin-left: .355em;
        vertical-align: .1em;
    }

}

// .inq-hmid-st {
//     background: #ede8ca;
// }

.inq-hmid2-st {
    float: right;
    float: right;
    padding-top: 0rem;
    a {
        font-size: 12px;
        font-weight: 500;
    }
}

.inq-sec-h-st {
    padding-top: 1.5rem;
    .inq-logo-st {
        img {
            max-width: 85%;
            cursor: pointer;
        }
    }
    .inq-sec-h-fld-st {
        position: relative;
        .form-control {
            height: calc(2em + .75rem + 2px);
            border-radius: 1.5rem;
            position: relative;
            margin-top: .4rem;
        }
        span {
            position: absolute;
            right: 17px;
            top: 10px;
            .fa {
                font-size: 18px;
                color: #777;
                cursor: pointer;
            }
        }
    }
    .inq-rev-st {
        .col-md-4 {
            padding-right: 0px;
        }
        .col-md-8 {
            padding-left: 10px;
            .fa-star {
                color: $unchecked-star;
                font-size: 12px;
            }
            .checked {
                color: $checked-star;
            }
            .inq-rev-txt-st {
                h4 {
                    margin-bottom: -0.5rem;
                }
            }
        }
    }
    .inq-rimg-st {
        img {
            max-width: 80%;
            position: relative;
        }
        span {
            position: absolute;
            top: 11px;
            right: 18px;
            font-size: 12px;
        }
    }
    .inq-limg-st {
        img {
            max-width: 85%;
        }
    }
    .inq-cimg-st {
        img {
            max-width: 75%;
            position: relative;
        }
        span {
            position: absolute;
            right: 30px;
        }
    }
    .inq-ltext-st {
        line-height: 1.3;
        padding-left: 0px;
        p {
            font-size: 13px;
            text-transform: uppercase;
        }
        p:first-child {
            font-weight: 500;
        }
    }
}

// menu
.inq-menu-st {
    .navbar {
        padding: 0rem 1rem;
        ul {
            li {
                list-style: none;
                color: #ddd;
                text-transform: uppercase;
                margin-right: 1.5rem;
                margin-left: 1.5rem;
                &.active {
                    border-bottom: 2px solid $menu-underline-color;
                }
                a {
                    position: relative;
                    font-weight: 500;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 2px;
                        top: 0;
                        left: 0;
                        right: 0px;
                    }
                }
            }
        }
    }
}

.inq-hr-st {
    margin-bottom: 0rem !important;
}

.inq-hr1-st {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

.inq-Caro-st {
    width: 100%;
    // height: 51vh;
    background-position: top center;
    background-size: cover;
    position: relative;
    @if $product-cd == "aiema"{
        margin-bottom: 0;
    }
    @else{
        margin-bottom: 3rem;
    }
    &::before {
        content: "";
        background: $carousel-bg-color;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .inq-sli-btn-st {
        position: absolute;
        bottom: 20%;
        left: 24%;
        top: 30%;
        @if $product-cd =="haven"{
            text-align: center;
        }   
    }
}

.inq-home-about-st {
    img {
        width: 30%;
        margin-bottom: 1.5rem;
    }
    h5 {
        font-size: 1rem !important;
        font-weight: bolder; position:relative;
    }
}

.inq-home-ser-st {
    img {
        width: 100%;
    }
}

.inq-testi-st {
    h5 {
        position: relative;
        display: flex;
        text-align: center;
        flex-direction: row;
        &::before,
        &::after {
            content: "";
            flex: 1 1;
            margin: auto 10px;
            border-bottom: 2px solid $slogan-color;
        }
    }
}

.inq-food-ch-st {
    padding-left: 17px;
    padding-right: 17px;
}

.inq-slider-st {
    width: 100%;
    height: 45vh;
    // background: url('assets/images/vendor/banner1.png') top center;
    background-size: cover;
    position: relative;
    margin-bottom: 3rem;
    &::before {
        content: "";
        background: $mh-bg-color;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .inq-slider-inner-st {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        // padding-top: 80px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        h1,
        div {
            color: $mh-color;
        }
    }
}

.inq-menu-left-st {
    border-right: 1px solid $md-border;
    padding-right: 2rem;
    height: 100%;
    ul li {
        text-align: right;
        line-height: 2;
        font-weight: 400;
        cursor: pointer;
        &:hover {
            color: $menu-txt-color;
            font-weight: 500;
        }
        &.active {
            color: $menu-txt-color;
            font-weight: 500;
            position: relative;
            font-size: 18px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: -30px;
                border-right: 3px solid $menu-txt-color;
            }
        }
    }
}


.inq-menu-left1-st {
    border-right: 1px solid $md-border;
    padding-right: 2rem;
    height: 100%;
    ul li {
        line-height: 2;
        font-weight: 400;
        margin-left:35px;
        cursor: pointer;
        &:hover {
            color: $menu-txt-color;
            font-weight: 500;
        }
        &.active {
            color: $menu-txt-color;
            font-weight: 500;
            position: relative;
            font-size: 18px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: -30px;
                border-right: 3px solid $menu-txt-color;
            }
        }
    }
}

.fa-style i.fa{
    font-size: 9px !important;
    background: $secondary-button-text;
    color: $white;
    padding: 3px;
    border-radius: 25px;
    width: 14px;
    line-height: 10px;
}
.fa-style i.fa:hover{
    background: $primary;
    color: $white;
    cursor: pointer;   
}

.inq-menu-right-st {
    .inq-title-st {
        p {
            color: $product-listing-heading-color;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                background-color: $product-listing-heading-color;
                width: 32px;
                height: 2px;
                top: 25px;
                left: 1px;
            }
        }
    }
    .inq-ft-st {
        img {
            width: 15px;
        }
    }
    .inq-fo-des-in-st {
        padding-left: 0px;
    }
    .inq-dis-sec-st {
        p:last-child {
            font-size: 13px;
            color: #6c757d;
        }
    }
    .inq-fo-im-st {
        margin-top: 1rem;
        img {
            max-width: 100%;
            height: 100px;
            border-radius: 10px;
        }
        .inq-cart-bw-st {
            display: flex;
            flex-basis: auto;
            background: #efefef;
            padding: 0.1rem .5rem;
            border: 1px solid #eee;
            bottom: -9px;
            position: relative;
            min-width: 100px;
            .inq-sp-1,
            .inq-sp-2,
            .inq-sp-3 {
                text-align: center;
            }
            .inq-sp-1 {
                flex: 1;
                color: $black;
            }
            .inq-sp-2 {
                flex: 2;
                color: $pallet2;
                // margin-left: 18px;
            }
            .inq-sp-3 {
                flex: 1;
                color: $pallet2;
            }
        }
    }
    .inq-pro-hr-st {
        width: 83%;
        border: 0;
        border-bottom: 1px solid #cdcdcd;
        margin-top: 3rem;
        margin-bottom: -1rem;
    }
    .fa-rupee {
        font-size: 12.5px;
    }
}

.inq-pro-col-le-st {
    padding-left: 0px !important;
}

.inq-news-st {
    background: $signup-ft-bg;
    min-height: 200px;
    padding-top: 3rem;
    margin-top: 2rem;
    .inq-news-fld-st {
        position: relative;
        .form-control {
            height: calc(2em + .75rem + 2px);
            border-radius: 1.5rem;
            position: relative;
            margin-top: .4rem;
        }
        span {
            position: absolute;
            right: 1px;
            top: 0px;
            background: $pallet2;
            // font-size: 12px;
            cursor: pointer;
            // line-height: 1.7;
            color: $white;
            padding: 10px 30px;
            border-radius: 1.5rem;
            .fa {
                font-size: 18px;
                color: $light-ash;
            }
        }
    }
}

.inq-foot-bg-st {
    background: $footer-bg;
    color: $white;
    padding: 2rem 0rem 0rem 0rem;
    margin-top: -5rem;
    border-radius: 20px;
    li {
        font-weight: 400; font-size: 14px; margin: 0 0 6px;
    }
    .inq-visi-st {
        visibility: hidden;
    }
    .inq-foot-logo-st {
        img {
            max-width: 100%;
        }
    }
    .inq-foot-links-st {
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                flex: 1;
                flex-basis: auto;
            }
        }
    }
    .inq-foot-pd-st {
        padding: 11px;
    }
}

.inq-footql-st {
    @if $product-cd == "murugesan"{
        background: #797676;
    }
    @else{
        background: $primary;
    }
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 1rem;
    padding-bottom: .2rem;
    margin-bottom: 1.5rem;
    p,
    li {
        font-size: 13px;
    }
}

.inq-btn-st {
    padding: .375rem 1.5rem !important;
}
 
// Checkout Styles 
.inq-cart-ul-st {
    border-bottom: none !important;
    li.nav-item {
        font-size: 14px;
        text-align: center;
        cursor: default;
        min-width: 150px;
        background-color: #f2f2f2;
        transition: background-color 0.2s ease;
        color: #fff !important;
        position: relative;
        padding: 0px 0px 0px 24px;
        margin: 0 4px;
        &.current {
            background: $footer-bg;
            color: #fff !important;
            &::after {
                border-left: 18px solid $footer-bg;
            }
        }
        &.next {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &.next1 {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &.next2 {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &.next3 {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &.next4 {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &.next5 {
            background: $checkout-tab-color;
            color: #fff !important;
            &::after {
                border-left: 18px solid $checkout-tab-color;
            }
        }
        &::before,
        &::after {
            content: " ";
            position: absolute;
            top: 1px;
            right: -18px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 18px solid #f2f2f2;
            z-index: 2;
            transition: border-color 0.2s ease;
        }
        &::before {
            right: auto;
            left: 0;
            border-left: 20px solid #fff;
            z-index: 0;
        }
        a.nav-link {
            color: rgba(0,0,0,0.5) !important;
            background: none;
            border: none !important;
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
            padding: .6rem 1rem;
            &:hover {
                border-color: none;
            }
            
            
             
        }
    }
}

.inq-order-sec-st {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.inq-pl0-st {
    padding-right: 5px !important;
}

.inq-pr0-st {
    padding-left: 5px !important;
}

.inq-order-sec2-st {
    padding-left: 30px !important;
}

.inq-order-inner-p-st {
    p {
        padding-bottom: 10px;
        border-bottom: 1px solid #777;
    }
}

.inq-cart-ord-ti-st {
    margin-left: -15px;
}

.inq-cart-ul-st li.nav-item.current a.nav-link {
    color: #fff !important;
    
}
.inq-cart-ul-st li.nav-item.next a.nav-link {
    color: #fff !important;
    
}
.inq-cart-dinfo-st {
    padding-left: 0px !important;
    padding-right: 0px !important;
    h6 {
        background: #8c945b;
        color: #fff;
        padding: 0.5rem 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-bottom: 0px !important;
    }
    .inq-cart-ddinfo-st {
        background: #ede8ca;
        padding: 1rem!important;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .inq-pribg-st,
        .inq-secbg-st {
            padding: .2rem 1rem;
            border-radius: 5px;
            margin-bottom: 1rem;
            color: #fff;
        }
        .inq-pribg-st {
            background: #805425;
        }
        .inq-secbg-st {
            background: #f2f2f2;
        }
        .form-group {
            margin-bottom: .5rem !important;
            label {
                margin-bottom: 0.2rem !important;
                font-weight: 500;
                display: block;
                font-size: 13px;
            }
            small {
                font-size: 77% !important;
            }
            .form-control {
                background: #f1efda !important;
                color: #333 !important;
            }
        }
    }
}

.inq-cart-fitems-st {
    .table th {
        border-top: none !important;
    }
    .inq-fimg-st {
        background: #f2f2f2;
        padding: .5rem;
        img {
            width: 100%;
        }
    }
    .inq-fimg-mt1-st {
        margin-top: 1.4rem;
    }
    .inq-fimg-mt2-st {
        margin-top: 2.6rem;
    }
    // .inq-amt-st {
    //     font-size: initial;
    // }
    .inq-amt-st.l {
        font-weight: 700;
    }
}

.inq-news-fld-st {
    position: relative;
    .form-control {
        // height: calc(2em + .75rem + 2px);
        border-radius: 1.5rem;
        position: relative;
        margin-top: .4rem;
        font-weight: 500;
    }
    .ind-input-abs{
        position: absolute;
        right: 1px;
        top: 4px;
        cursor: pointer;
    }
    span {
        
        background: #805425;
       
        color: #fff;
        padding: 9px 30px;
        border-radius: 1.5rem;
        .fa {
            font-size: 18px;
            color: #777;
        }
    }
}

.inq-cart-pay-st {
    .form-check-inline {
        background: #e8ecef;
        padding: .4rem .8rem;
    }
}

.inq-upshell-prd-st {
    background: #c5c5c5;
    text-align: center;
    padding: 1rem;
    // min-height: 298px;
    .inq-upshell-prdimg-st {
        background: #e8e8e8;
        border-radius: 15px;
        padding: 2.5rem 0rem;
        height: 20rem;
        img {
            max-width: 100%;
            padding: .5rem;
            margin-bottom: 1rem;
        }
    }
    .inq-upshell-wimg-st {
        background: #ede8ca;
    }
}


.inq-pro-rating-st {
    width: 22px;
    margin-top: -4px;
}

.inq-onl-del-st {
    position: fixed;
    right: 0px;
    top: 50%;
    cursor: pointer;
    z-index: 10;
}

.inner-star-icon{color: #828281;}

.mayan-inner-star-icon{color: #ffffff;}

// ------------------------------------------------------------------------------------//
// responsive styles 
@media (min-width: 1200px) {
    .container {
        max-width: 93% !important;
    }
    .inq-sec-h-st {
        .inq-head-col-1 {
            flex: 0 0 12.666667%;
            max-width: 12.666667%;
        }
        .inq-head-col-3 {
            flex: 0 0 16%;
            max-width: 16%;
        }
        .inq-head-col-4 {
            flex: 0 0 15.666667%;
            max-width: 15.666667%;
        }
        .inq-head-col-5 {
            flex: 0 0 14.333333%;
            max-width: 14.333333%;
            .col-md-7 {
                line-height: 1.3;
                .inq-re-sr {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            span {
                font-size: 13px;
            }
        }
        .inq-head-col-6 {
            flex: 0 0 7.333333%;
            max-width: 7.333333%;
        }
    }

    .inq-cart-bw-st {
        margin: -25px 15px !important;
    }
}

// mobile
@media screen and (max-width:600px) {
    html,
    body {
        overflow-x: hidden;
    }
    .inq-sec-h-st {
        padding-top: .5rem;
        .inq-logo-st img {
            max-width: 85%;
        }
        .inq-hlogo-st {
            flex: 0 0 36.333%;
            max-width: 36.333%;
            order: 1;
        }
        .col-md-4.inq-head-col-2 {
            order: 4;
            margin-top: 1rem;
        }
        .col-md-3.inq-head-col-3 {
            flex: 0 0 36.333%;
            max-width: 36.333%;
            text-align: center;
            order: 2;
        }
        .col-md-1.inq-head-col-5 {
            flex: 0 0 50%;
            max-width: 50%;
            order: 5;
            margin-top: 1rem;
            .col-md-5 {
                flex: 0 0 30%;
                max-width: 30%;
            }
            .col-md-7 {
                flex: 0 0 70%;
                max-width: 70%;
            }
            .inq-re-sr {
                display: block;
            }
        }
        .inq-prof-st {
            flex: 0 0 50%;
            max-width: 50%;
            order: 6;
            margin-top: 1rem;
            .col-md-4 {
                flex: 0 0 40%;
                max-width: 40%;
                order: 2;
                text-align: center;
                padding-right: 0px;
            }
            .col-md-8 {
                flex: 0 0 60%;
                max-width: 60%;
                order: 1;
                padding-right: 0px;
                position: relative;
                right: -20px;
                text-align: end;
                top: 3px;
            }
        }
        .col-md-1.inq-head-col-6 {
            order: 2;
            flex: 0 0 27.333%;
            max-width: 27.333%;
        }
        .inq-cimg-st {
            img {
                max-width: 50%;
            }
            span {
                right: 25px;
                font-size: 13px;
            }
        }
        .inq-limg-st img {
            max-width: 50%;
            text-align: center !important;
        }
        .inq-rimg-st {
            img {
                max-width: 85%;
                text-align: center !important;
            }
            span {
                top: 7px;
                right: 14px;
            }
        }
        .inq-rev-st {
            justify-content: center!important;
            .col-md-4 {
                flex: 0 0 45%;
                max-width: 45%;
                order: 1;
            }
            .col-md-8 {
                flex: 0 0 55%;
                max-width: 55%;
                order: 2;
                padding-left: 6px;
                padding-right: 6px;
                .fa-star {
                    font-size: 13px;
                }
                .inq-rev-txt-st h4 {
                    font-size: 1rem;
                }
            }
        }
    }
    span.pl-1 {
        display: none;
    }
    .inq-menu-st .navbar {
        justify-content: space-around;
    }
    .inq-menu-right-st {
        .inq-fo-im-st {
            img {
                // max-width: 50%;
                width: 50%;
            }
            text-align: center;
            .inq-cart-bw-st {
                margin: -25px 120px;
            }
        }
        .inq-pro-hr-st {
            width: 100%;
            margin-top: 3.5rem;
            margin-bottom: -1rem;
        }
    }
    .inq-news-st {
        background: $signup-ft-bg;
        min-height: auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .text-uppercase {
        text-align: center;
    }
    .inq-foot-bg-st {
        margin-top: 0rem;
        border-radius: 0px;
    }
    footer .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .inq-foot-logo-st,
    .inq-foot-pd-st {
        text-align: center;
    }
    .inq-mt-st {
        margin-bottom: 4rem;
    }
    .inq-menu-left-st {
        border-right: none;
        padding-right: 0rem;
        overflow: scroll;
        margin-bottom: 1rem;
        ul li {
            padding-left: 1rem;
            text-align: left;
        }
    }
    .inq-hr1-st {
        margin-bottom: .5rem;
    }
    figure {
        margin: 0 0 0rem;
    }
    .inq-htop-st {
        justify-content: left;
        // padding-left: .5rem;
        .fa {
            right: 12px;
            top: 10px;
        }
    }
    .inq-prof-st {
        .row {
            justify-content: center;
        }
    }
    .inq-footql-st {
        margin-bottom: 0rem;
    }
    // checkout
    .inq-order-inner-p-st {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .inq-cart-ord-ti-st {
        margin-left: 0px;
    }
    .inq-cart-dinfo-st {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .inq-upshell-prd-st {
        margin-bottom: 1rem;
        .inq-upshell-prdimg-st {
            height: 25rem;
        }
    }
    .inq-pro-col-le-st,
    .inq-fo-des-in-st {
        padding-left: 15px !important;
    }
    .inq-com-pd-st {
        padding-left: 15px;
        padding-right: 15px;
    }
    .inq-mch-con-btn-st {
        margin-top: .5rem;
        margin-bottom: .8rem;
    }
    .inq-upshell-wimg-st {
        p {
            margin-top: 1rem;
            margin-bottom: 1rem !important;
        }
    }
    .inq-cart-ul-st li.nav-item {
        // width: 47%;
        margin-bottom: 1rem;
    }
    .inq-order-sec2-st {
        padding-left: 15px !important;
    }
    // .inq-order-row-st {
    //     margin-left: -30px !important;
    //     margin-right: -30px !important;
    // }
    .inq-cart-pay-st .form-check-inline {
        width: 46%;
        margin-bottom: 1rem;
    }
    .inq-mc-ordn-st {
        margin-top: 2rem !important;
    }
    .inq-mc-ordn1-st {
        margin-top: 1rem !important;
    }
    .inq-Caro-st {
        height: 24vh;
        .w-100 {
            height: 24vh;
        }
        .inq-sli-btn-st {
            left: 23%;
            bottom: 9px;
        }
        button.btn.btn-primary.inq-btn-st {
            padding: 2px 5px !important;
            font-size: 6px;
            margin-top: 13px;
        }
    }
    .inq-pr0-st {
        padding-left: 15px !important;
    }
    .inq-pl0-st {
        padding-right: 15px !important;
    }
}

.inq-hmid1-st .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 #fff;
}
.inq-hmid1-st .nav-tabs .dropdown-menu{    padding: 0; font-size: 12px; font-weight: 500;}
.inq-hmid1-st .nav-tabs .dropdown-menu a.dropdown-item{    padding:2px 10px; font-size: 12px; font-weight: 500;}

// mobile rotate 

@media screen and (max-width:767px) and (min-width:481px) {
    .inq-menu-right-st .inq-fo-im-st .inq-cart-bw-st {
        width: 150px !important;
    }
    .inq-Caro-st .inq-sli-btn-st {
        position: absolute;
        bottom: 8% !important;
        left: 16% !important;
    }
    
    
}

.owl-theme .owl-dots .owl-dot {
    display: none !important;
}

.owl-theme .owl-nav [class*=owl-] {
    border-radius: 30px !important;
    padding: 8px 13px !important;
    border: 1px solid #b7b6b5 !important;
}

@media screen and (max-width:1024px){

    .inq-Caro-st .inq-sli-btn-st {
        position: absolute;
        bottom: 20%;
        left: 24%;
        top: 10%;
        text-align: center;
    }

}

@media screen and (max-width:768px){

    .inq-sli-btn-st h3 {
        margin: 0;
        font-size: 26px;
        line-height: 33px;
        font-weight: 700;
    }
    .inq-Caro-st .inq-sli-btn-st p{
        font-size: 20px;
    }
    .inq-Caro-st .inq-sli-btn-st p span {
        font-size: 20px;
        color: $pallet2;
    }
}

@media screen and (max-width:500px){
    .inq-Caro-st .w-100{
        height: auto;
    }
    .inq-sli-btn-st h3 {
        margin: 0;
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
    }
    .inq-Caro-st .inq-sli-btn-st p {
        font-size: 15px;
    }
    .inq-Caro-st .inq-sli-btn-st p span {
        font-size: 15px;
    }
    .inq-Caro-st .inq-sli-btn-st{
        left: 14%;
    }
    .inq-Caro-st button.btn.btn-primary.inq-btn-st {
        padding: 2px 5px !important;
        font-size: 6px;
        margin-top: -18px;
    }
    .inq-Caro-st{
        height: auto;
    }
}

main{
    background-color: $bg-color;
}

.footer-murugesan{
    border: 1px solid #d2232a !important;
    color: #d2232a !important;
    background-color: #a1a1a1 !important;
}

.footer-mayan{
    border: 1px solid #3c707c !important;
    color: #3c707c !important;
    background-color: #C2C1BD !important;
}

ngb-rating {
    color: goldenrod
  }