// @import './themes/havenSampoorna/haven-themes';
@import './themes/tams/tams-themes';
//@import './themes/earthworthy/earthyworthy-themes';

// Theme Scss
@import  './themes/earthworthy/variables';
@import  './themes/bbold/variables';

:root {
  --theme-deafult: #E31E25;
  --theme-textgray: #9B9898;
  
}
// Owl Carousel
@import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/* Importing Bootstrap SCSS file. */
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './themes/earthworthy/style';
@import './themes/earthworthy/responsive';
@import './themes/bbold/style';
@import './themes/bbold/responsive';



