.login-newcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.login-newcard{
    border-radius: 1rem;
    padding: 10px 15px;
}

.login-newcard::before{
    position: absolute;
    top: 2rem;
    right: -0.5rem;
    content: '';
    background: $primary;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
}

.login-newcard::after{
    position: absolute;
    content: attr(data-label);
    top:11px;
    right: -14px;
    padding: 0.5rem;
    width: 10rem;
    background: $primary;
    color: white;
    text-align: center;
    box-shadow: 4px 4px 15px rgba(26,25, 126,0.2);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.surprise-continer{
    background-color: $primary;
}

.title {
  margin-top: 10px !important;
    font-size: clamp(36px, 0.6rem, 100px);
    color: #fff;
    text-shadow: 2px 2px #d54604;
  }
  [class^='firework-'] {
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
  }
  
  $count: 15;
  $frame-list: firework-sm, firework-md, firework-lg;
  @for $i from 1 through $count {
    .firework-#{$i} {
      animation: nth($frame-list, random(3)) 1.2s both infinite;
      animation-delay: #{random($count) * 0.1}s;
      top: random(16) * 5% + 10%;
      left: random(16) * 5% + 10%;
    }
  }
  
  
  // dots generator
  @function dots($r) {
    $result: ();
    // straight
    @for $i from 1 through 4 {
      $x: 0;
      $y: 0;
      @if $i <= 2 {$x: $r} @else {$y: $r}
      @if $i % 2 != 0 {$x: -$x; $y: -$y}
      $result: append($result, #{$x}rem #{$y}rem 0 #fff, comma);
    }
    // diagonal
    @for $i from 1 through 4 {
      $dist2: $r * 0.7;
      $x: $dist2;
      $y: $dist2;
      @if $i > 2 {$x: -$x}
      @if $i % 2 != 0 {$y: -$y}
      $result: append($result, #{$x}rem #{$y}rem 0 #fff, comma);
    }
    @return $result;
  }
  
  // firework animation
  @mixin fireworkSize($name, $r) {
    @keyframes firework-#{$name} {
      0%,
      100% {
        opacity: 0;
      }
      10%,
      70% {
        opacity: 1;
      }
      100% {
        box-shadow: dots($r);
      }
    }
  }
  @include fireworkSize(sm, 0.5);
  @include fireworkSize(md, 0.7);
  @include fireworkSize(lg, 0.9);
  
  .surprise-text{
      font-size: 20px;
      color: $white;
      padding: 15px 20px !important;
  }

  .surprise-btn{
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    padding: 3px 30px !important;
    background-color:#d54604;
    color: #fff;
  }