.thumb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   
}
.thumb1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   
}
.thumb-content{
  text-align: left;
}
.price-area{
  margin-top: 4%;
}
.cart-buttons{
  float: right;
}
.product-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  
//   padding: 20px;
//   font-size: 1.3rem;
//   margin: 0;
//   background: white;
  // resize: horizontal;
}
.product-list-price1{
  font-size: 20px !important;
  color: #2554C7 !important;
  font-weight: 600 !important;
}
.product-list-tile1{
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
}
.product-list-description1{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}
.product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  // width: 60% !important;
  border-radius: 10px !important;
  object-fit: contain;
}
.listing{
 border-radius: 6px !important;
 padding: 5px 20px !important;
        
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.collection-filter {

  background-color: #fff;
  .filter-top {
    border-bottom: 1px solid #dddddd;
    .filter-clear {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
  .layerd-navigation {
    padding: 10px;
    // font-family: "Inter-Regular";
    border-bottom: 1px solid #dddddd;
    .collection-collapse-block {
      margin-bottom: 0px;
      .collection-collapse-block-content {
        padding-left: 30px;
        .options {
            margin: 8px 0px 8px 0px;
            input {
                margin-right: 10px;
            }
            label{
                margin-bottom: 0px;
                font-size: 15px;
                .count {
                    color: #afabab;
                    font-size: 14px;
                }
            }
        }
      }
    }
  }
}